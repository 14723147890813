import type { ZoneAPIError, ZoneAPIResponse } from '../types';

interface State {
  zoneResponse: ZoneAPIResponse | null;
  zoneError: ZoneAPIError | null;
  max_depth: number;
}

export const zoneStore = defineStore('zoneStore', {
  state: (): State => ({
    zoneResponse: null,
    zoneError: null,
    max_depth: 2,
  }),
  getters: {
    getZoneResponse(): State['zoneResponse'] {
      return this.zoneResponse;
    },
    getZoneError(): State['zoneError'] {
      return this.zoneError;
    },
  },
  actions: {
    setZoneResponse(zoneResponse: State['zoneResponse']): void {
      this.zoneResponse = zoneResponse;
    },
    setZoneError(zoneError: State['zoneError']): void {
      this.zoneError = zoneError;
    },
    setMaxDepth(maxDepth: State['max_depth']): void {
      this.max_depth = maxDepth;
    },
  },
});
